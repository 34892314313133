import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { FarewellScreenRequestItem, TimeModeEntry } from '@data-terminal/shared-models';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FarewellApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        private readonly ccAuthService: CCAuthService
    ) {}

    private readonly FAREWELL_SCREEN_REQUEST_URL: (userId: string) => string = (userId: string) =>
        `${this.backendUrl}${this.orgRequestPrefix}farewellscreen/${userId}`;

    public getFarewellScreenData(body: FarewellScreenRequestItem[]): Observable<TimeModeEntry[]> {
        const email = this.ccAuthService.getCurrentUser()?.email;
        if (email) {
            return this.http.post<TimeModeEntry[]>(this.FAREWELL_SCREEN_REQUEST_URL(email), body);
        }
        return of([]);
    }
}
