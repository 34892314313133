import { Injectable } from '@angular/core';
import { FarewellScreenRequestItem, TimeModeEntry } from '@data-terminal/shared-models';
import { Observable } from 'rxjs';
import { FarewellApiService } from './farewell-api.service';

@Injectable({
    providedIn: 'root',
})
export class FarewellService {
    private farewellRequestItems: FarewellScreenRequestItem[] = [];

    constructor(private readonly farewellApiService: FarewellApiService) {}

    public addFarewellRequestItems(items: FarewellScreenRequestItem[]): void {
        this.farewellRequestItems.push(...items);
    }

    public clearFarewellRequestItems(): void {
        this.farewellRequestItems = [];
    }

    public getFarewellScreenData(): Observable<TimeModeEntry[]> {
        return this.farewellApiService.getFarewellScreenData(this.farewellRequestItems);
    }

    public hasFarewellScreenData(): boolean {
        return this.farewellRequestItems.length > 0;
    }
}
